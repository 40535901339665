<template>
    <div class="ui grid">
        <div class="row centered">
            <div class="eight wide column">
                <h1 class="dossier-welcome text-capitalize">Bonjour {{CURRENT_USER.nom}}</h1>
            </div>
        </div>
        <div class="row centered">
            <div class="eight wide column">
                <h2 class="dossier-section-title">Telechargement des documents</h2>
            </div>
        </div>
        <div class="row centered">
            <div class="ten wide column">
                <div class="search-results-header">
                    <input type="text" class="searchInput" placeholder="Recherche ..." v-model="searchInput" @input="getSearch">
                </div>
                  <tableType1 v-bind="table" @onSetPageClick="setPage">
                    <template v-slot:action="slotprops">
                      <td class="action">
                        <button @click="$store.openDocument(slotprops.entry.link)" class="mini ui icon button">
                          <i class="download icon"></i>
                        </button>
                        <button @click="openPDF(slotprops.entry)" class="mini ui icon button" :class={disabled:!$store.isPDF(slotprops.entry.name)}>
                          <i class="eye icon"></i>
                        </button>
                      </td>
                    </template>
                  </TableType1>
            </div>
        </div>
        <modal name="doc-preview-modal" width="720px" style="border-radius: 20px" :adaptive="true"  height="auto" >
          <vue-pdf-app style="height: 80vh;" :pdf="currentPDF"></vue-pdf-app>
           <div class="myFooter">
              <div>
                <div class="Modal__File_Title mb-1">Nom du document</div>
                <div class="Modal__File_Value ">{{currentPDFInfo && currentPDFInfo.name ? currentPDFInfo.name : '-------------'}}</div>
              </div>
              <div>
                <div class="Modal__File_Title mb-1">Date</div>
                <div class="Modal__File_Value ">{{currentPDFInfo && currentPDFInfo.tmsp ? $SHARED.utils.getDateOnly(currentPDFInfo.tmsp): '-------------'}}</div>
              </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import tableType1 from "@/components/generic/tableType1"

    export default {
        components: {
            tableType1
        },
        data() {
            
            return {
                selectedType:"",
                searchInput:"",
                currentPDF :"",
                currentPDFInfo:{},
                table: {
                  action:true,
                  data: [],
                  headers: [
                    {label: "NR", value: "_id",filter:(id)=>this.table.data.findIndex(e=>e._id===id)+1},
                    {label: "Type du document", value: "sous_type"},
                    {label: "Nom du document", value: "name"},
                    {label: "Date", value: "tmsp",filter:(data)=>this.$SHARED.utils.getDate(data)}
                  ],
                  pagination: {
                    page: 1,
                    limit: 10,
                    count: 0
                  },
                  isBusy: false
                },
            }
        },
      computed:{
        CURRENT_USER(){return this.$store.state.current_user},
      },
        methods: {
          openPDF(attachement) {

            this.currentPDF=this.$store.state.SERVER_ADDRESS+attachement.link
            this.currentPDFInfo =  attachement
            this.$modal.show('doc-preview-modal')

          },
          setPage(page){

            console.log("page: "+page)
            this.table.pagination.page=page
            this.getDocuments()
          },
          getDocuments() {
              console.log('getDocuments');

              let query={
                // id_dossier:this.$store.state.dossier._id,
                user_id: this.$store.state.current_user._id,
                type: 'publication'
              }
              if(this.searchInput){
                query['name']={$regex: this.searchInput, $options: 'i'}
              }

              const data = {
                query,
                options: {
                  limit:this.table.pagination.limit,
                  page:this.table.pagination.page,
                  withCount:true
                }
              };
              this.table.isBusy = true;

              this.$store.dispatchAsync(this.$SHARED.services.attachement.list, data).then(ar => {

                this.table.data=ar.docs;
                this.table.pagination.count = ar.count;

              }).finally(() => {
                this.table.isBusy = false;
              })
          },

          getSearch:
            _.debounce(function(){
              this.getDocuments()
            },1500)
        },
        mounted () {
          this.getDocuments();
        },
    }
</script>

<style  scoped>
.dossier-welcome{
    font-weight: 300;
    font-size: 4em;
    color: var(--main-color-blue);
    text-align: center;
    line-height: 1;
    padding-bottom: 0.35em;
    border-bottom: 2px solid var(--main-color-blue);
}
.dossier-section-title{
    color: var(--main-color-blue);
    text-align: center;
    font-weight: 800;
    font-size: 2.25em;
}
.dossier-upload-container{
    background-color: white;
    padding: 2em;
  
    box-shadow: 0px 0px 33px -8px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
}
.dossier-upload-body{
  /* min-height: 300px; */
}
.dossier-upload-footer{
    margin-top: 1em;
    display: flex ;
    align-items: center;
    justify-content: space-between;
}
/* upload  table */
.upload-table {
    border-spacing: 1em;
}

.file-to-upload{
    color:var(--main-color-blue);
    font-weight: 500;
    border:1px solid var(--main-color-blue);
    border-radius: 0.3em;
    padding: 0.5em 1em;
    margin: 0em 1em;
}
.file-upload-container.complete .file-to-upload{
    color:#FFF;
    background-color:var(--main-color-green);
    border-color:var(--main-color-green) ;
}
.file-upload-container.loading  .file-to-upload{
    color:var(--main-color-orange);
    border-color:var(--main-color-orange) ;
}
.file-progress{
    color: rgba(17, 62, 150, 0.49);
    font-weight: 500;
    background: #EEF1F7;
    border-radius: 5px;
    text-align: center;
    min-width: 400px;
     padding: 0.5em 2em;
}
.file-upload-container.complete .file-progress{
    color: #fff;
    background-color: var(--main-color-green);
    
}
.file-upload-container.loading .file-progress{
    color: #fff;
    background-color: var(--main-color-orange);
    
}
.file-upload{
    margin: 0em 1em;
}
.file-upload label{
    border-radius: 5px;
    color:#fff;
    background-color: var(--main-color-blue);
    display: inline-block;
    padding: 0.5em 1em;
    cursor: pointer;
}
.file-upload input[type=file]{
    display: none;
}
.upload-submit{
    border:none;
    border-radius: 5px;
    color:#fff;
    text-transform: uppercase;
    font-weight: 500;
    background-color: var(--main-color-green);
    padding: 0.75em 1em;
    cursor: pointer;
}
.submited-title{
    font-weight: 700;
    font-size: 2.25em;
    text-transform: uppercase;
    line-height: 1.5;
    text-align: center;
    color: var(--main-color-orange);
}
.submited-message{
    font-weight: 400;
    font-size: 2.125em;
    /* text-transform: uppercase; */
    line-height: 1.5;
    text-align: center;
    color: var(--main-color-blue);
}
.search-container{
    border:1px solid var(--main-color-blue);
    /* min-height: 200px; */
    padding: 2em;
    border-radius: 3.14em;
}   
.search-results-header{
    padding: 0.25em;
   display: flex;
   justify-content: space-between;
   align-items: baseline;

}
.search-results-header > h2{
    margin: 0em;
    line-height: 1;
    color: var(--main-color-blue);
    
}
.search-results-header > .searchInput{
   border-radius:9px;
   padding: 0.5em 1em;
   border: 1px solid var(--main-color-blue);

}
.search-results-header > .searchInput::placeholder {
    font-weight: 600;
    color: rgba(15, 101, 165, 0.46);
}

.myFooter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    padding: 1em;
    background-color: var(--main-color-blue);
}
.Modal__File_Title{
    color:white;
    font-size: 1.25em;
    margin-bottom: 0.5em;
    font-weight: 400;
    text-decoration: underline;
}
.Modal__File_Value{
    color:white;
    font-size: 1.5em;
    font-weight: 600;
}
#pdf-app{
  width: 400px;
  height: 450px;
}
.search-results-header{
  margin-bottom: 15px;
}
td.action button{
  background-color: var(--main-color-blue);
  color:white;
  margin: 0 5px;
  box-shadow: 1px 1px 1px gray;
}
td.action button:hover{
  background-color: var(--main-color-blue-dark);
  color:white;
}
td.action{
  text-align: center;
}
</style>